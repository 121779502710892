<template>
  <div class="home">
    <v-layout row wrap class="d-flex justify-center">
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text">
          <router-link to="/">Pro Track</router-link> /
          <router-link to="/accounts">Accounts</router-link> /
          {{ name }}
        </h1>
      </v-flex>
      <v-flex xs12>
        <v-layout row wrap>
          <v-flex xs12 md12 class="pa-3">
            <div style="width: 200px">
              <div
                style="
                  width: 200px;
                  height: 200px;
                  background-color: #212121;
                  padding: 10px;
                "
              >
                <img
                  :src="imageSrc"
                  style="width: 100%; height: 100%; object-fit: cover"
                  v-if="imageSrc"
                />
                <div
                  class="grey darken-3"
                  v-else
                  style="width: 100%; height: 100%"
                ></div>
              </div>
              <v-btn
                class="primary mt-3"
                block
                rounded
                @click="handleFileImport"
                >Choose Image</v-btn
              >
              <input
                ref="uploader"
                class="d-none"
                type="file"
                @change="onFileChanged"
              />
            </div>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Name</p>
            <v-text-field
              label="Full Name"
              single-line
              outlined
              v-model="name"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Email</p>
            <v-text-field
              label="Email"
              single-line
              outlined
              v-model="email"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Phone</p>
            <v-text-field
              label="Phone"
              single-line
              outlined
              v-model="phone"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Department</p>
            <v-select
              label="Select Role"
              single-line
              outlined
              v-model="department"
              :items="departments"
              item-text="name"
              item-value="_id"
            ></v-select>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Role</p>
            <v-select
              label="Select Role"
              single-line
              outlined
              v-model="role"
              :items="roles"
              item-text="name"
              item-value="_id"
            ></v-select>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Reporting To</p>
            <v-select
              label="Reporting to"
              single-line
              outlined
              v-model="reporting_to"
              :items="users"
              item-text="name"
              item-value="_id"
            ></v-select>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <v-btn
              large
              block
              v-if="!loading"
              class="primary black--text"
              @click="updateAccount"
              >Submit</v-btn
            >
            <v-btn large block v-else class="primary"
              ><v-progress-circular indeterminate></v-progress-circular
            ></v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
    
    <script>
import { BASE_URL } from "@/config";
import Axios from "axios";

export default {
  data() {
    return {
      role: "",
      roles: ["Super Admin", "Admin", "Department Lead", "Team Member"],
      department: "",
      departments: [],
      users: [],
      reporting_to: "",
      name: "",
      phone: "",
      email: "",
      password: "",
      confirm_password: "",
      send_mail: true,
      files: [],
      fileName: "",
      imageSrc: null,
      loading: false,
    };
  },
  mounted() {
    this.fetchDepartments();
    this.fetchUser();
    this.fetchAccount();
  },
  computed: {
    getFileName() {
      return (
        this.fileName.substring(0, 12) +
        "......." +
        this.fileName.substring(this.fileName.length - 10)
      );
    },
  },
  methods: {
    handleFileImport() {
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.files = e.target.files;
      this.fileName = this.files[0].name;
      this.convertImageToDataURL();
    },
    convertImageToDataURL() {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageSrc = e.target.result;
      };
      reader.readAsDataURL(this.files[0]);
    },
    async fetchAccount() {
      let url = BASE_URL + "/user/" + this.$route.params.id;
      let { data } = await Axios.get(url);
      console.log(JSON.stringify(data));
      this.imageSrc = data.display_picture;
      this.name = data.name;
      this.email = data.email;
      this.phone = data.phone;
      this.role = data.role;
      this.department = data.department;
      this.reporting_to = data.reporting_to;
    },
    async fetchUser() {
      let url = BASE_URL + "/user";
      let { data } = await Axios.get(url);
      this.users = data;
    },
    async fetchRoles() {
      let url = BASE_URL + "/role";
      let { data } = await Axios.get(url);
      this.roles = data;
    },
    async fetchDepartments() {
      let url = BASE_URL + "/department";
      let { data } = await Axios.get(url);
      this.departments = data;
    },
    async updateAccount() {
      this.loading = true;
      let url = BASE_URL + "/user/" + this.$route.params.id;
      let payload = new FormData();
      payload.append("name", this.name);
      payload.append("email", this.email);
      payload.append("phone", this.phone);
      payload.append("role", this.role);

      if (typeof this.department === "string") {
        payload.append("department", this.department);
      } else {
        payload.append("department", this.department._id);
      }
      if (this.reporting_to) {
        if (typeof this.reporting_to === "string") {
          payload.append("reporting_to", this.reporting_to);
        } else {
          payload.append("reporting_to", this.reporting_to._id);
        }
      }
      payload.append("password", this.password);
      if (this.files[0]) {
        payload.append("photos", this.files[0]);
      }
      let { data } = await Axios.patch(url, payload);
      this.$toastr.s(
            "Account saved successfully","Saved",
          );
      this.loading = false;
    },
  },
};
</script>